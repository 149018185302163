import { shareReplay } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppSetting } from './../global/appsetting';
import { GaAnalyticsService, EVENT_TYPES } from './ga-analytics.service';
import { ConfigView, Config } from '../models/configs/configs';


const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
@Injectable()
export class ServerConfigService {
  overloadConfigItem = { overloadConfig: new Config() };
  blockOrderConfigItem = { blockConfig: new Config() };
  displayOverLoad = { displayOverLoad: true };
  constructor(private http: HttpClient, public appsetting: AppSetting,
    private gaAnalyticsService: GaAnalyticsService) {
    this.overloadConfigItem.overloadConfig.Ivalue = 0;
    this.GetConfigByKey('isOverLoad').subscribe((res: Config) => {
      this.overloadConfigItem.overloadConfig = res;
    }, error => {
      this.gaAnalyticsService.logEventToGa('constructor isOverLoad', 'ServerConfigService', EVENT_TYPES.Error);
      console.log(error);
    });
    this.GetConfigByKey('shouldBlockOrders').subscribe((res: Config) => {
      this.blockOrderConfigItem.blockConfig = res;
    }, error => {
      this.gaAnalyticsService.logEventToGa('shouldBlockOrders', 'ServerConfigService', EVENT_TYPES.Error);
      console.log(error);
    });
  }
  GetConfigByKey(key: string) {
    return this.http.get(this.appsetting.siteUrl + 'api/config/item/' + key, { headers: headers }).pipe(shareReplay());
  }
  GetAllConfigs() {
    return this.http.get(this.appsetting.siteUrl + 'api/config/configs', { headers: headers }).pipe(shareReplay());
  }
  SetConfigItem(configView: ConfigView) {
    return this.http.put(this.appsetting.siteUrl + 'api/config/config', configView, { headers: headers });
  }
  SetConfigItems(configViews: ConfigView[]) {
    return this.http.put(this.appsetting.siteUrl + 'api/config/configs', configViews, { headers: headers });
  }
}

