import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { AppSetting } from './../global/appsetting';
import { HttpClient, HttpHeaders,} from '@angular/common/http';
import { Comment } from '../models/comments/comments';

@Injectable()
export class CommentsService {

  commentApi = this.appsetting.siteUrl + 'api/comment/';
  
  constructor(private http: HttpClient, public appsetting: AppSetting) { }
  GetCommentByProductID(productId: string) {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.get(this.commentApi + productId, { headers: headers });
  }

  GetLatestComments(): Observable<Comment[]> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.get<Comment[]>(this.commentApi + 'latestComments/20', { headers: headers });
  }
 

  InsertComment(comment: Comment, captchaResponse: string) {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' }).append('captchaResponse', captchaResponse);
    return this.http.post(this.appsetting.siteUrl + 'api/comment', comment, { headers: headers });
  }
  TestIfUserExist(email: string) {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.get(this.commentApi + 'is-user-exist?email='+email , { headers: headers });
  }

}


