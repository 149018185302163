import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpRequest } from '@angular/common/http';

import { AppSetting } from './../global/appsetting';

@Injectable()
export class EmailServiceService {

  constructor(private http: HttpClient, public appsetting: AppSetting) { }
  sendMail(mail: Mail) {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const params = new HttpParams().set('body', mail.body)
      .append('userEmail', mail.userEmail)
      .append('mailType', mail.mailType.toString())
      .append('recaptchaRes', mail.captchaResponse);
    return this.http.post(this.appsetting.siteUrl + 'Mailer/SendMail', { headers: headers }, { params: params });
  }
  sendMailAskUs(mail: Mail) {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const params = new HttpParams().set('body', mail.body)
      .append('userEmail', mail.userEmail)
      .append('mailType', mail.mailType.toString())
      .append('recaptchaRes', mail.captchaResponse);
    return this.http.post(this.appsetting.siteUrl + 'AskUs/SendUsMail', mail);
  }
  sendMailWithFiles(mail: Mail) {
    const formData: FormData = new FormData();
    for (let j = 0; j < mail.fileList.length; j++) {
      formData.append('files', mail.fileList[j], mail.fileList[j].name);
    }
    const params = new HttpParams().set('body', mail.body)
      .append('userEmail', mail.userEmail).append('mailType', mail.mailType.toString());

    const req = new HttpRequest('POST', this.appsetting.siteUrl + 'Mailer/SendMailWithFiles', formData, { params: params });
    return this.http.request(req);
  }
}
export class Mail {
  userEmail: string;
  body: string;
  mailType: number;
  fileList: FileList;
  captchaResponse: string;
}
