import { Injectable } from '@angular/core';
import { AppSetting } from './../global/appsetting';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, shareReplay } from 'rxjs';
import { Coupon, CouponView } from '../models/coupons/coupons';

@Injectable()
export class CouponService {
  displayCouponCodeForm = false;
  listOfAvailbleCoupons: Array<Coupon>;
  couponApi = this.appsetting.siteUrl + 'api/coupon/';
  coupons$: Observable<Coupon[]>;

  constructor(private http: HttpClient, public appsetting: AppSetting) { }

  GetCouponById(couponId: number) {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.get(this.couponApi + couponId, { headers: headers });
  }

  GetAllActiveCoupons() {
    if(!this.coupons$){
      const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
      this.coupons$ = this.http.get<Coupon[]>(this.couponApi + 'active-coupons', { headers: headers }).pipe(shareReplay());
    }
    return this.coupons$;
  }

  InsertNewCoupon(coupon: Coupon, userPass: string, captchaResponse: string) {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' })
      .append('token', userPass).
      append('captchaResponse', captchaResponse);
    return this.http.post(this.appsetting.siteUrl + 'api/coupon', coupon, { headers: headers });
  }

  DeleteCoupon(couponId: number, userPass: string) {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' }).append('token', userPass);
    return this.http.delete(this.couponApi + couponId, { headers: headers });
  }

  GetCouponByCouponCodeEndUserEmail(CouponCodeUid: string, UserEmail: string) {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const options = {
      headers,
      body: {
        UserEmail
      },
    };
    return this.http.get(this.couponApi + 'coupon-code/' + CouponCodeUid + "?UserEmail=" + UserEmail);
  }

  DeleteCodeCouponByCouponId(CodeCouponId: number) {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.delete(this.couponApi + 'coupon-code/' + CodeCouponId, { headers: headers });
  }

  InsertCodeCoupon(couponView: CouponView, captchaResponse: string) {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' }).append('captchaResponse', captchaResponse);
    return this.http.post(this.couponApi + 'code-coupon', couponView, { headers: headers });
  }

}

