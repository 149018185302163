<footer>
  <div class="container-fluid">
   
    <div class="row">


      <div class="all-irght-reseve col-md-12" title="{{version}}">
        © {{currentYear}} כל הזכויות שמורות ל
        <span>סטודיו גיל אדריכלות</span>
        <div class="dev-credit">פיתוח:
          <a href="https://www.linkedin.com/in/eliran-brami-6135a8b6/?originalSubdomain=il" target="blank" title="Eliran Brami">אלירן ברמי</a>
        </div>
      </div>
      <div class="dev-credit-mobile col-md-12">פיתוח:
        <a href="https://www.linkedin.com/in/eliran-brami-6135a8b6/?originalSubdomain=il" title="Eliran Brami" target="blank">אלירן ברמי</a>
      </div>
    </div>
  </div>
</footer>