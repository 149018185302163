import { AppSetting } from './../global/appsetting';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpRequest } from '@angular/common/http';
import { ArticleView } from '../models/articles/articles';


@Injectable()
export class ArticlesService {

  articleApi = this.appsetting.siteUrl + 'api/article'
  constructor(private http: HttpClient, public appsetting: AppSetting) { }
  DeleteArticle(id: number) {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.delete(this.articleApi + '/' + id, { headers: headers });
  }
  EditArticle(articleView: ArticleView, file: any, password: string) {
    const formData: FormData = new FormData();
    if (file != null) { formData.append('files', file, file.name); }
    const params = new HttpParams().set('_articleView', JSON.stringify(articleView)).append('pass', password);
    const req = new HttpRequest('PUT', this.articleApi, formData, { params: params });
    return this.http.request(req);

  }
  GetAllArticles() {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.get(this.articleApi + '/articles', { headers: headers });
  }
  GetLimitedArticles(start, pageSize) {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.get(this.articleApi + `/articles/limited/${start}/${pageSize}`, { headers: headers });
  }
  GetLimitedArticlesByTag(start, pageSize, tagS) {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.get(this.articleApi + `/articles/limited/by-tag/${start}/${pageSize}/${tagS}`, { headers: headers });
  }
  GetArticleViewById(id: number) {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.get(this.articleApi + '/article-view/' + id, { headers: headers });
  }
  InsertArticle(articleView: ArticleView, password: string, token: string) {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const insertArticleView = {
      ArticleView: articleView,
      Token: token,
      Password: password,
    };
    const req = new HttpRequest('POST', this.articleApi, insertArticleView, { headers: headers });
    return this.http.request(req);
  }
  DeleteTag(id: number) {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.delete(this.articleApi + '/' + id, { headers: headers });
  }
  SaveFiles(id: number, files: Array<any>) {
    const formData: FormData = new FormData();
    if (files[0] != null && files[1] != null && files[0].length>0 && files[1].length>0) {
      files.forEach(file => {
        formData.append('files', file, file.name);
      });
      const params = new HttpParams().set('id', id.toString());
      const req = new HttpRequest('POST', this.articleApi+ 'files', formData, { params: params });
      return this.http.request(req);
    }


  }
}

