import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';


const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./modules/contents/home-page/home-page.module').then(mod => mod.HomePageModule),
    pathMatch: 'full'
  },
  {
    path: 'archonline',
    loadChildren: () => import('./modules/contents/home-page/home-page.module').then(mod => mod.HomePageModule)
  },
  {
    path: 'archonline_product_list',
    loadChildren: () => import('./modules/contents/product-list/product-list.module').then(mod => mod.ProductListModule)
  },
  {
    path: 'archonline_how_it_works',
    loadChildren: () => import('./modules/contents/home-page/home-page.module').then(mod => mod.HomePageModule)
  },
  {
    path: 'archonline_about',
    loadChildren: () => import('./modules/contents/home-page/home-page.module').then(mod => mod.HomePageModule)
  },
  {
    path: 'archonline_ask_us',
    loadChildren: () => import('./modules/contents/home-page/home-page.module').then(mod => mod.HomePageModule)
  },
  {
    path: 'p',
    loadChildren: () => import('./modules/contents/product-details/product-details.module').then(mod => mod.ProductDetailsModule)
  },

  {
    path: 'archonline_product_details/:id',
    redirectTo: 'p/archonline_product_details/:id'
  },
  {
    path: 'archonline_product_details/:id/:i',
    redirectTo: 'p/archonline_product_details/:id/:i'
  },
  {
    path: 'archonline_product_details_part2/:id',
    redirectTo: 'p/archonline_product_details_part2/:id'
  },
  {
    path: 'archonline_product_details_part2/:id/:i',
    redirectTo: 'p/archonline_product_details_part2/:id/:i'
  },
  {
    path: 'product/:id/:title',
    redirectTo: 'p/product/:id/:title'
  },
  {
    path: 'product/:id/:i/:title',
    redirectTo: 'p/product/:id/:i/:title'
  },
  {
    path: 'product2/:id/:title',
    redirectTo: 'p/product2/:id/:title'
  },
  {
    path: 'product2/:id/:i/:title',
    redirectTo: 'p/product2/:id/:i/:title'
  },
  {
    path: 'aoc/archonline_basket',
    loadChildren: () => import('./modules/contents/basket/basket.module').then(mod => mod.BasketModule)
  },
  {
    path: 'aoc/archonline_article_list',
    loadChildren: () => import('./modules/contents/article-list/article-list.module').then(mod => mod.ArticleListModule)
  },
  {
    path: 'aoc/archonline_article_list/:tagS',
    loadChildren: () => import('./modules/contents/article-list/article-list.module').then(mod => mod.ArticleListModule)
  },
  {
    path: 'aoc/archonline_article/:id',
    loadChildren: () => import('./modules/contents/articles/articles.module').then(mod => mod.ArticlesModule)
  },
  {
    path: 'aoc/articles/:id/:title',
    loadChildren: () => import('./modules/contents/articles/articles.module').then(mod => mod.ArticlesModule)
  },
  {
    path: 'aoc/archonline_article',
    loadChildren: () => import('./modules/contents/articles/articles.module').then(mod => mod.ArticlesModule)
  },
  {
    path: 'TransactionSuccess/Msg/:id',
    loadChildren: () => import('./modules/contents/transaction-success/transaction-success.module').
    then(mod => mod.TransactionSuccessModule)
  },
  {
    path: 'TransactionFaild/Msg/:id',
    loadChildren: () => import('./modules/contents/transaction-faild/transaction-faild.module').then(mod => mod.TransactionFaildModule)
  },
  {
    path: 'chat-admin',
    loadChildren: () => import('./modules/share/share.module').then(mod => mod.ShareModule)
  },
  {
    path: 'admin',
    loadChildren: () => import('./modules/contents/admin/admin.module').then(mod => mod.AdminModule)
  },
  {
    path: '**',
    loadChildren: () => import('./modules/contents/error/error.module').then(mod => mod.ErrorModule)

  }
];
@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule],
})
export class AppRoutingModule { }
