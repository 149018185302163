import { Injectable } from "@angular/core";

declare const gtag: any;
declare const gtag_report_conversion: any;
@Injectable({
  providedIn: "root",
})
export class GaAnalyticsService {
  constructor() {}

  logEventToGa(action: string, label: string, category: string) {
    gtag("event", action, { event_category: category, event_label: label });
  }

  addToConversion(sum, optionID, callback) {
    gtag("event", "conversion", {
      send_to: "AW-1032366887/jYDBCKXqv-QCEKfWouwD",
      value: sum,
      currency: "ILS",
      transaction_id: optionID,
      event_callback: callback,
    });
  }
}

export enum EVENT_TYPES {
  Error = "ERROR",
  Pay = "PAY",
}
