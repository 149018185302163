import { Injectable } from '@angular/core';
import { MetaTagService } from './meta-tag.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';

@Injectable()
export class HeaderService {
  isHomePage = false;
  currentTab = -1;
  isInAMiddleOfScroll = { value: false };
  scrollHeight = { value: 0 };
  justArrivedPage = true;
  constructor(private router: Router,
    private route: ActivatedRoute,
    private location: Location,
    private metaTagService: MetaTagService) {

     }
  checkTab(doScroll) {
    switch (window.location.pathname) {
      case '/':
      case '/archonline': {
        this.changeTab(-1);
        this.isHomePage = true;
        break;
      }
      case '/archonline_about': {
        if (doScroll) {
          this.scrollTOItem(1);
        }
        this.changeTab(0);
        this.isHomePage = true;
        break;
      }
      case '/archonline_how_it_works': {
        if (doScroll) {
          this.scrollTOItem(2);
        }
        this.changeTab(2);
        this.isHomePage = true;
        break;
      }
      case '/archonline_ask_us': {
        if (doScroll) {
          this.scrollTOItem(3);
        }
        this.changeTab(3);
        this.isHomePage = true;
        break;
      }
      case '/archonline_product_list': {
        this.changeTab(1);
        break;
      }
      case '/aoc/archonline_article_list': {
        this.changeTab(4);
        break;
      }
      default: {
        this.changeTab(-1);
        if(this.route.snapshot.fragment==='comments'){
         setTimeout(()=>{
          document.getElementById('comments').scrollIntoView({behavior: 'smooth'});
         }, 500);
        }
        else{
          document.querySelector('html').scrollTop = 0;
        }
        
      }
    }
    this.justArrivedPage = true;
  }
  changeTab(id) {
    this.currentTab = id;
  }
  scrollTOItem(index): void {
    this.isInAMiddleOfScroll.value = true;
    if (!this.isHomePage) {
      window.localStorage.setItem('routFromOtherPage', '1');
    }
    document.querySelector('#menu-' + index).scrollIntoView({behavior: 'smooth', block: 'center'});
    
  }

  changeUrlLocation(urlStr, title): void {
    this.metaTagService.updateMetaTags(title, null, null);
    const url = this
      .router
      .createUrlTree([urlStr], { relativeTo: this.route })
      .toString();

    this.location.go(url);
  }
  
  isElementInViewport(el): boolean {
    if (el) {
      const rect = el.getBoundingClientRect();
      const res = (rect.top + (rect.bottom - rect.top) / 2) <= (window.innerHeight ||
        document.documentElement.clientHeight); /*or $(window).height() */
      return res;
    } else {
      return false;
    }
  }
}
