import { AfterViewChecked, ApplicationRef, ChangeDetectorRef, Injectable, OnInit } from '@angular/core';

@Injectable()
export class LoaderService {

  showLoader: boolean;
  showWorkingProgress: boolean;
  workInProgressTxts = ['ההזמנה שלכם מועברת ממש ברגעים אלו למערכת התשלום',
    'המערכת שלנו שומרת את כל הפרטים שהזנתם ומעבדת אותם', 'המתינו ומיד תועברו לדף התשלום',
    'אם הוספתם קבצים גדולים זמן העיבוד יכול להמשך מעט יותר', 
    'העלאת קבצים יכולה להמשך עד כ-40 דקות תלוי במהירות האינטרנט שלכם'
  ];
  workInProgressTxt = this.workInProgressTxts[0];
  makeChangeEffect = false;
  loaderStuck = [];
  constructor() {
    this.showLoader = false;
    this.showWorkingProgress = false;
  }


  displayLoader(showWorkingProgress: boolean): void {
    setTimeout(()=>{
      this.loaderStuck.push(true);
      this.showLoader = true;
      document.querySelector('.anim-to-disable')?.classList.add('disable-anim');
      if(showWorkingProgress){
        this.showWorkingProgress = true;
        this.generateText();
      }
    });
   
  }

  hideLoader(): void {
    setTimeout(()=>{
      this.loaderStuck.pop();
      if(this.loaderStuck.length===0){
        this.showLoader = false;
      }
      this.showWorkingProgress = false;
      document.querySelector('.anim-to-disable')?.classList.remove('disable-anim');
    });
    
  }

  generateText(): void {
    this.workInProgressTxt = this.workInProgressTxts[0];
    this.workInProgressTxts.forEach((item, i) => {
      if (i !== 0) {
        setTimeout(() => {
          this.makeChangeEffect = true;
          setTimeout(() => {
            this.makeChangeEffect = false;
            this.workInProgressTxt = this.workInProgressTxts[i];
            if (i === (this.workInProgressTxts.length - 1)) {
              this.generateText();
            }
          }, 700);

        }, 5000 * i);
      }
    });
  }
}
